import React from "react";
import SingularityNet from "../../assets/SingularityNet.svg";
import FetchAi from "../../assets/FetchAi.svg";
import Ocean from "../../assets/Ocean.svg";
import { Logo } from "../../assets/ExportSvgs";
function Hero() {
	return (
		<div className="flex flex-col justify-between h-[70vh] lg:h-[90vh] rounded-[30px] bg-primary p-4 lg:p-8 lg:pr-[47%]">
			<div className="md:hidden flex items-center justify-start p-0">
				<Logo className="w-[80%]" />
			</div>
			<div className="hidden md:flex items-center justify-start p-0">
				<Logo />
			</div>

			<h1 className="text-4xl lg:text-7xl font-normal">
				ASI must be open, decentralized, built for the benefit of humanity and
				other sentient beings
			</h1>
			<div className="space-y-4 lg:hidden">
				<p className="text-xs">A partnership between</p>
				<div className="flex items-center justify-between gap-x-7">
					<a
						href="https://singularitynet.io/"
						target="_blank"
						rel="noreferrer"
						className="w-full">
						<img
							src={SingularityNet}
							alt=""
							className="w-[100%] hover:opacity-30 cursor-pointer"
						/>
					</a>
					<a
						href="https://fetch.ai/"
						target="_blank"
						rel="noreferrer"
						className="w-full">
						<img
							src={FetchAi}
							alt=""
							className="w-[100%] hover:opacity-30 cursor-pointer"
						/>
					</a>
					<a
						href="https://oceanprotocol.com/"
						target="_blank"
						rel="noreferrer"
						className="w-full">
						<img
							src={Ocean}
							alt=""
							className="w-[100%] hover:opacity-30 cursor-pointer"
						/>
					</a>
				</div>
			</div>
			<div className="hidden lg:flex lg:items-center lg:gap-5 w-full">
				<p className="text-sm w-[40%]">A partnership between</p>
				<div className="flex items-center justify-between gap-x-7 w-full">
					<a
						href="https://singularitynet.io/"
						target="_blank"
						rel="noreferrer"
						className="w-full">
						<img
							src={SingularityNet}
							alt=""
							className="w-[100%] hover:opacity-30 cursor-pointer"
						/>
					</a>
					<a
						href="https://fetch.ai/"
						target="_blank"
						rel="noreferrer"
						className="w-full">
						<img
							src={FetchAi}
							alt=""
							className="w-[100%] hover:opacity-30 cursor-pointer"
						/>
					</a>
					<a
						href="https://oceanprotocol.com/"
						target="_blank"
						rel="noreferrer"
						className="w-full">
						<img
							src={Ocean}
							alt=""
							className="w-[100%] hover:opacity-30 cursor-pointer"
						/>
					</a>
				</div>
			</div>
		</div>
	);
}

export default Hero;
