import PageRouter from "./router/PageRouter";

function App() {
	return (
		<div>
			<PageRouter />
		</div>
	);
}

export default App;
