import React, { useState } from "react";
import Plus from "../../assets/Plus.svg";

function ButtonsSection() {
	const [showContent, setShowContent] = useState(false);
	return (
		<div className="py-4 border-t border-b transition-all duration-700">
			<div className="flex items-center gap-x-5 lg:gap-x-7">
				<div>
					<img src={Plus} alt="" />
				</div>
				<div className="space-x-4">
					<button
						onClick={() => setShowContent(!showContent)}
						className={`${
							showContent ? "bg-primary" : "bg-white"
						}  hover:bg-primary px-6 rounded-full text-sm lg:text-md h-[38px]`}>
						Migration Guide
					</button>
					<a
						href="https://docs.superintelligence.io/"
						target="_blank"
						rel="noreferrer"
						onClick={() => setShowContent(false)}>
						<button className="bg-white hover:bg-primary px-6 rounded-full text-sm lg:text-md h-[38px]">
							Documentation <span className="text-lg">↧</span>
						</button>
					</a>
				</div>
			</div>

			{/* content  */}

			<div
				className={`text-white space-y-4 transition-all duration-700 pt-6 ${
					showContent
						? "opacity-100 translate-y-0"
						: "-translate-y-12 opacity-0 sr-only"
				}`}>
				<h2 className="text-lg">
					<strong>Important Token Merge Details</strong>
				</h2>
				<div className="px-4 space-y-4">
					<ul className="show">
						<li>
							$FET will migrate to $ASI, at a conversion rate of 1 $FET to 1
							$ASI
						</li>
						<li>
							$AGIX tokens migrate to $ASI, at a conversion rate of 1 $AGIX to
							0.433350 $ASI
						</li>
						<li>
							$OCEAN tokens migrate to $ASI, at a conversion rate of 1 $OCEAN to
							0.433226 $ASI
						</li>
					</ul>
					<ul className="show">
						<li>
							Migration links and specifics for non-custodial wallets will be
							provided{" "}
							<a href="/select-wallet" className="text-primary font-bold">
								here
							</a>
							.
						</li>
						<li>Exchanges will communicate their individual processes</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default ButtonsSection;
