import React from "react";
import Hero from "../components/landing/Hero";
import PageLayout from "../layout/PageLayout";
import ButtonsSection from "../components/landing/ButtonsSection";

function LandingPage() {
	return (
		<PageLayout>
			<div className="pt-7 lg:pt-8 space-y-10">
				<Hero />
				<ButtonsSection />
			</div>
		</PageLayout>
	);
}

export default LandingPage;
