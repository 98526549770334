import React from "react";
import PageLayout from "../../layout/PageLayout";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function Footer() {
	return (
		<PageLayout>
			<footer className="py-5 transition-all duration-700 w-full flex flex-col gap-y-5 lg:flex-row items-center justify-center lg:justify-between">
				<div className="flex items-center justify-between lg:justify-start lg:gap-10 w-full">
					<a
						href="https://t.me/ASI_Alliance"
						target="_blank"
						rel="noreferrer"
						className="flex items-center gap-x-2 text-white hover:text-primary">
						<div className="w-6 h-6 rounded-full bg-white flex items-center justify-center text-sm">
							<FaTelegramPlane className="text-secondary" />
						</div>
						<p className="text-sm">Join our telegram channel</p>
					</a>
					<a
						href="https://t.me/ASI_Alliance"
						target="_blank"
						rel="noreferrer"
						className="flex items-center gap-x-2 text-white hover:text-primary">
						<div className="w-6 h-6 rounded-full bg-white flex items-center justify-center text-sm">
							<FaXTwitter className="text-secondary" />
						</div>
						<p className="text-sm">Follow us on X</p>
					</a>
				</div>
				<ul className="flex lg:gap-10 text-sm items-center justify-between lg:justify-end w-full">
					{footerLinks.map((nav, index) => {
						return (
							<li key={index}>
								<a
									href={nav.path}
									target="_blank"
									rel="noreferrer"
									className="text-white hover:text-primary">
									{nav.name}
								</a>
							</li>
						);
					})}
				</ul>
			</footer>
		</PageLayout>
	);
}

export default Footer;

const footerLinks = [
	{
		name: "Privacy Policy",
		path: "https://www.superintelligence.io/",
	},
	{
		name: "Cookie Policy",
		path: "https://www.superintelligence.io/",
	},
	{
		name: "Terms & Conditions",
		path: "https://www.superintelligence.io/",
	},
];
