import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { square } from "ldrs";

square.register();

function ConnectModal({ setShowModal, selectedListing }) {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	const handleClick = () => {
		navigate(`/select-wallet/${selectedListing.name}`);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoading(false);
		}, 7000);

		return () => clearTimeout(timer);
	}, []);
	return (
		<div className="h-screen fixed inset-0 bg-black bg-opacity-55 flex items-center justify-center px-4">
			<div className="w-full max-w-xl p-4 bg-primary rounded-xl">
				<div className="mb-3 flex items-center justify-end">
					<button
						className="text-primary text-lg p-2 bg-secondary rounded-full"
						onClick={() => setShowModal(false)}>
						<MdClose />
					</button>
				</div>

				{/* body  */}

				{loading ? (
					<div className="flex flex-col items-center justify-center gap-y-5 py-4">
						<img
							src={selectedListing.image_url.sm}
							alt=""
							className="rounded-lg"
						/>
						<div>
							<p className="text-center font-bold text-sm text-secondary">
								Connecting to {selectedListing.name}
							</p>
							<p className="text-center">Please wait</p>
						</div>
						<Spinner />
					</div>
				) : (
					<div className="flex flex-col items-center justify-center gap-y-5 py-4">
						<img src={selectedListing.image_url.sm} alt="" />
						<div>
							<p className="text-center font-bold text-sm text-red-700">
								An Error Occurred
							</p>
							<p className="text-center">Try another option</p>
						</div>
						<button
							onClick={handleClick}
							className="w-full bg-secondary text-primary px-6 rounded-full text-sm lg:text-md py-4">
							Connect Manually
						</button>
					</div>
				)}
			</div>
		</div>
	);
}

export default ConnectModal;

export const Spinner = () => {
	return (
		<l-square
			size="35"
			stroke="5"
			stroke-length="0.25"
			bg-opacity="0.1"
			speed="1.2"
			color="black"></l-square>
	);
};
