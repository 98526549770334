import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import Footer from "../components/footer/Footer";
import SelectWallet from "../pages/SelectWallet";
import SelectedWallet from "../pages/SelectedWallet";

function PageRouter() {
	const [selectedListing, setSelectedListing] = useState({});

	return (
		<Router>
			<Routes>
				<Route exact path="/" element={<LandingPage />} />
				<Route
					path="/select-wallet"
					element={
						<SelectWallet
							selectedListing={selectedListing}
							setSelectedListing={setSelectedListing}
						/>
					}
				/>
				<Route
					exact
					path="/select-wallet/:id"
					element={
						<SelectedWallet
							selectedListing={selectedListing}
							setSelectedListing={setSelectedListing}
						/>
					}
				/>
			</Routes>
			<Footer />
		</Router>
	);
}

export default PageRouter;
