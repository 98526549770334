import React, { useEffect, useState } from "react";
import walletLists from "../data/walletsList";
import PageLayout from "../layout/PageLayout";
import { Logo } from "../assets/ExportSvgs";
import ConnectModal from "../components/modal/ConnectModal";

function SelectWallet({ selectedListing, setSelectedListing }) {
	const [displayCount, setDisplayCount] = useState(24);
	const [searchInput, setSearchInput] = useState("");
	const listingsArray = Object.values(walletLists.listings);
	const filteredListings = listingsArray.filter((listing) =>
		listing.name.toLowerCase().includes(searchInput.toLowerCase())
	);
	const displayedListings = filteredListings.slice(0, displayCount);
	const [showModal, setShowModal] = useState(false);

	const loadMoreListings = () => {
		setDisplayCount(displayCount + 10);
	};

	const handleSearchChange = (e) => {
		setSearchInput(e.target.value);
		setDisplayCount(10);
	};

	const handleWalletBoxClick = (listing) => {
		setSelectedListing(listing);
		setShowModal(true);
	};
	console.log(selectedListing);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<PageLayout>
			<div className="space-y-10 pt-8">
				<div className="rounded-[30px] bg-primary p-4 lg:p-8">
					<a href="/" className="md:hidden flex items-center justify-start p-0">
						<Logo className="w-[80%]" />
					</a>
					<a href="/" className="hidden md:flex items-center justify-start p-0">
						<Logo />
					</a>
				</div>

				{/* Content  */}
				<div>
					<div className="space-y-8">
						{/* Search  */}
						<div className="relative w-full mx-auto">
							<div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
								<svg
									className="w-4 h-4 text-gray-500 dark:text-gray-400"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 20 20">
									<path
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
									/>
								</svg>
							</div>
							<input
								type="search"
								id="default-search"
								value={searchInput}
								onChange={handleSearchChange}
								className="block w-full p-4 ps-10 text-sm text-white border border-primary rounded-lg bg-transparent focus:ring-primary focus:border-primary focus:outline-none caret-primary"
								placeholder="Search wallet by name..."
								required
							/>
							<button
								type="submit"
								className="text-secondary absolute end-2.5 bottom-2.5 bg-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm px-4 py-2 ">
								Search
							</button>
						</div>

						{/* Wallets  */}
						<div className="mx-auto grid grid-cols-3 lg:grid-cols-6 gap-3 place-items-center place-content-center">
							{displayedListings.map((listing) => (
								<WalletBox
									key={listing.id}
									imageUrl={listing.image_url.sm}
									name={listing.name}
									onClick={() => handleWalletBoxClick(listing)}
								/>
							))}
						</div>

						{/* Load more Button  */}
						<div className="max-w-2xl mx-auto flex items-center justify-center py-10">
							{displayCount < listingsArray.length && (
								<button
									onClick={loadMoreListings}
									className="w-full bg-white hover:bg-primary px-6 rounded-full text-sm lg:text-md py-4">
									Load More..
								</button>
							)}
						</div>
					</div>
				</div>
			</div>

			{showModal && (
				<ConnectModal
					setShowModal={setShowModal}
					selectedListing={selectedListing}
				/>
			)}
		</PageLayout>
	);
}

export default SelectWallet;

function WalletBox({ imageUrl, name, onClick }) {
	return (
		<div
			onClick={onClick}
			className="cursor-pointer p-2 bg-primary bg-opacity-10 w-full h-32 flex flex-col items-center justify-center gap-2 rounded-lg hover:border-2 hover:border-primary hover:border-opacity-30">
			<img
				src={imageUrl}
				alt={`${name} logo`}
				className="w-[56px] h-[56px] rounded-lg"
			/>
			<p className="text-center text-white text-sm font-semibold">{name}</p>
		</div>
	);
}
