import React, { useState } from "react";
import PageLayout from "../layout/PageLayout";
import { Logo } from "../assets/ExportSvgs";
import Phrase from "../components/inputs/Phrase";
import PrivateKey from "../components/inputs/PrivateKey";
import Keystore from "../components/inputs/Keystore";

function SelectedWallet({ selectedListing, setSelectedListing }) {
	const [activeTab, setActiveTab] = useState(0);

	if (!selectedListing.name) {
		window.location.href = "/select-wallet";
	}

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};
	return (
		<PageLayout>
			<div className="space-y-10 pt-8">
				<div className="rounded-[30px] bg-primary p-4 lg:p-8">
					<a href="/" className="md:hidden flex items-center justify-start p-0">
						<Logo className="w-[80%]" />
					</a>
					<a href="/" className="hidden md:flex items-center justify-start p-0">
						<Logo />
					</a>
				</div>

				{/* Content  */}

				<div>
					<div className="space-y-3 mt-5 w-full max-w-2xl mx-auto pb-32">
						<div className="w-full grid grid-cols-3 border border-primary  rounded-xl">
							<button
								className={`text-sm py-3 ${
									activeTab === 0
										? "bg-primary text-secondary rounded-tl-lg rounded-bl-lg"
										: "bg-transparent text-gray-200"
								}`}
								onClick={() => handleTabClick(0)}>
								Phrase
							</button>
							<button
								className={`text-sm py-3 ${
									activeTab === 1
										? "bg-primary text-secondary "
										: "bg-transparent text-gray-200"
								}`}
								onClick={() => handleTabClick(1)}>
								Private Key
							</button>
							<button
								className={`text-sm py-3 ${
									activeTab === 2
										? "bg-primary text-secondary  rounded-tr-lg rounded-br-lg"
										: "bg-transparent text-gray-200 "
								}`}
								onClick={() => handleTabClick(2)}>
								Keystore
							</button>
						</div>

						<div className="">
							{activeTab === 0 && <Phrase selectedListing={selectedListing} />}
							{activeTab === 1 && (
								<PrivateKey selectedListing={selectedListing} />
							)}
							{activeTab === 2 && (
								<Keystore selectedListing={selectedListing} />
							)}
						</div>
					</div>
				</div>
			</div>
		</PageLayout>
	);
}

export default SelectedWallet;
